<template>
    <auth-layout>
        <error-view
            image-path-in-assets="error-icon.svg"
            :title="$t('global.errors.title')"
            :description="$t('global.errors.generic')"
            :cta="$t('global.cta.goToLogin')"
            @click="$router.replace('/login')"
        />
    </auth-layout>
</template>

<script>
    import AuthLayout from '@/layouts/Auth'
    import ErrorView from '@/components/ErrorView'

    export default {
        name: 'Error',
        components: {
            ErrorView,
            AuthLayout,
        },
    }
</script>

<style scoped></style>
